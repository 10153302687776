.BaseBack
{
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: black;
}

.TopMovie
{
    height: 100vh;
    margin-left: 50%;
    margin-right: 50%;
    background-color: black;
    transform: translateY(0) translateX(-50%);
}

.Menu
{
    width: 25vw;
    height: 4vw;
    position: absolute;
    top: 7vw;
    right: 0;
}
.MenuBtn
{
    width: 25vw;
    height: 4vw;
    background-color: white;
}
.MenuText
{
    margin-top: 3vw;
    margin-left: 2vw;
    font-size: 1.2vw;
    font-family: 'HiraMinProN-W6';
    transform: translateY(50%) translateX(0%);
}

.Information
{
    width: 100vw;
    height: 4vw;
    position: absolute;
    bottom: 5vw;
    margin-left: 50%;
    margin-right: 50%;
    background-color: white;
    transform: translateY(0) translateX(-50%);
}
.InformationLeftText
{
    position: absolute;
    left: 3vw;
    font-size: 1.2vw;
    font-family: 'HiraMinProN-W6';
}
.InformationLeftArrow
{
    color: rgb(196,173,148);
}
.InformationLeftLogo
{
    width: 6vw;
    position: absolute;
    bottom: 1.2vw;
    left: 22vw;
}
.InformationRightText
{
    position: absolute;
    right: 6vw;
    font-size: 1.2vw;
    font-family: 'HiraMinProN-W6';
}
.InformationRightLogo
{
    width: 2vw;
    position: absolute;
    bottom: 1.2vw;
    right: 3vw;
}

.header
{
    width: 60vh;
    max-width: 100vw;
    height: 7vh;
    position: absolute;
    top: 0;
    margin-left: 50%;
    margin-right: 50%;
    background-color: white;
    transform: translateY(0) translateX(-50%);
}
.Logo
{
    height: 3vh;
    margin-top: 2vh;
    margin-left: 2vh;
}
.LogoText
{
    margin-top: -3.5%;
    margin-left: 20vh;
    font-size: 1.8vh;
    font-family: 'HiraMinProN-W6';
    transform: translateY(-50%) translateX(0%);
}

.ScrollArea
{
    width: 60vh;
    max-width: 100vw;
    height: 93vh;
    overflow-y: Scroll;
    overflow-x: hidden;
    position: absolute;
    top: 7vh;
    margin-left: 50%;
    margin-right: 50%;
    transform: translateY(0) translateX(-50%);
    background-color: white;
}

.Page
{
    width: 60vh;
    max-width: 100vw;
    margin-left: 50%;
    margin-right: 50%;
    transform: translateY(0) translateX(-50%);
}
.Img1
{
    width: 150vh;
    margin-left: 50%;
    margin-right: 50%;
    transform: translateY(0) translateX(-50%);
}
.Img1true
{
    width: 10vh;
    margin-left: 50%;
    margin-right: 50%;
    transform: translateY(0) translateX(-50%);
}
.Img1Text
{
    position: absolute;
    margin-top: -50vh;
    margin-left: 5vh;
    font-size: 3.5vh;
    font-family: 'HiraMinProN-W6';
    color: white;
}

.page2
{
    margin-top: 0vh;
    background-color: rgb(235,235,235);
}
.Space2
{
    height: 5vh;
}
.SolutionTitle
{
    margin-top: 0vh;
    font-size: 3.5vh;
    font-family: 'HiraMinProN-W6';
    text-align: center;
}
.SolutionText
{
    margin-top: 0vh;
    font-size: 2vh;
    margin-left: 9vh;
    line-height: 5vh;
    font-family: 'HiraMinProN-W6';
}

.page3
{
    margin-top: 0vh;
}
.ServiceTitle
{
    font-size: 3.5vh;
    font-family: 'HiraMinProN-W6';
    text-align: center;
    background-color: rgb(255,255,255);
}
.ServiceImg
{
    width: 60vh;
    max-width: 100vw;
}

.page4
{
    margin-top: 0vh;
    background-color: rgb(196,173,148);
}
.Space5
{
    height: 2vh;
}
.ServiceTitle2
{
    margin-top: 0vh;
    font-size: 3.5vh;
    font-family: 'HiraMinProN-W6';
    text-align: center;
}
.ServiceText2
{
    margin-top: 0vh;
    font-size: 2vh;
    margin-left: 0vh;
    line-height: 5vh;
    font-family: 'HiraMinProN-W6';
    text-align: center;
}
.ServiceText2No
{
    font-size: 4vh;
}
.Img2
{
    width: 60vh;
}
.Service01false{}
.Service01true
{
    animation: rightSlide 5s normal 0s forwards;
}
.Service02false{}
.Service02true
{
    animation: rightSlide 5s normal 0s forwards;
}
.Service03false{}
.Service03true
{
    animation: rightSlide 5s normal 0s forwards;
}
.Service04false{}
.Service04true
{
    animation: rightSlide 5s normal 0s forwards;
}
@keyframes rightSlide
{
    from { opacity: 0; }
    to { opacity: 1; }
}
.Img2Text
{
    font-size: 1.5vh;
    margin-left: 5vh;
}
.Cost
{
    margin-left: 7vh;
    font-size: 3vh;
    text-align: left;
}
.Cost2
{
    margin-left: 7vh;
    font-size: 2.2vh;
    text-align: left;
}
.Cost2_2
{
    background-color: rgb(231,222,212);
}
.Border
{
    width: 50vh;
    margin-left: 5vh;
    border-bottom: solid;
}
.Cost3
{
    margin-left: 5vh;
    font-size: 3vh;
    text-align: left;
}
.Cost4
{
    margin-left: 5vh;
    font-size: 3.5vh;
    text-align: left;
}
.Cost5
{
    margin-left: 5vh;
    font-size: 1.8vh;
    text-align: left;
}
.CostCenter
{
    margin-left: 0vh;
    font-size: 3vh;
    text-align: center;
}

.NemyTitle
{
    width: 50vh;
    height: 6vh;
    position: absolute;
    margin-top: -8vh;
    margin-left: 5vh;
    font-size: 4vh;
    text-align: center;
    font-family: 'HiraMinProN-W6';
    background-color: rgb(231,222,212);
}
.NemyTitleSet
{
    width: 6vh;
    height: 6vh;
    margin-top: 2vh;
    margin-left: 27vh;
    background-color: rgb(231,222,212);
    transform:rotate(45deg); 
}
.NemyText
{
    margin-top: 0vh;
    font-size: 3vh;
    margin-left: 5vh;
    font-family: 'HiraMinProN-W6';
}
.ServiceColor
{
    background-color: rgb(255,255,255);
}
.FlashText
{
    border-radius: 10vh 10vh 10vh 10vh;
    animation: shine 5s infinite;
}
@keyframes shine {
    0%, 100% {
        box-shadow: 0 0 10px #ffc, 0 0 20px #ffc, 0 0 30px #ff9, 0 0 40px #ff6, 0 0 70px #fc6, 0 0 80px #f99, 0 0 100px #ff96, 0 0 150px #ff96;
      }
      50% {
        box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ffc, 0 0 40px #ff9;
      }
}
.Text2
{
    font-family: '';
}
.Text3
{
    font-size: 2.5vh;
    line-height: 5vh;
}
.Text4
{
    font-size: 2.4vh;
    line-height: 5vh;
}
.SlideImg
{
    width: 60vh;
    max-width: 100vw;
    overflow-x: Scroll;
}
.CaseImg
{
    height: 50vh;
}
.CaseImgfalse
{
    height: 50vh;
}
.CaseImgtrue
{
    height: 50vh;
    animation: CaseImg 18s normal 2s forwards;
}
@keyframes CaseImg
{
    0% { margin-left: 0vh; }
    100% { margin-left: -105vh; } 
}
.ArrowImg
{
    width: 60vh;
}
.page7
{
    margin-top: 0vh;
    background-color: rgb(196,173,148);
}

.Question
{
    margin-top: 0vh;
    margin-left: 5vh;
    font-size: 2.5vh;
    font-family: 'HiraMinProN-W6';
}
.Answer
{
    margin-top: 0vh;
    margin-left: 5vh;
    font-size: 2.5vh;
    line-height: 4vh;
    font-family: 'HiraMinProN-W3';
}

.page9
{
    margin-top: 0vh;
    background-color: rgb(196,173,148);
}
.fuder1
{
    margin-left: 5vh;
    font-size: 3vh;
    font-family: 'HiraMinProN-W6';
}
.fuder2
{
    font-size: 2.4vh;
    line-height: 4vh;
    font-family: 'HiraMinProN-W3';
}


@media screen and (min-aspect-ratio: 100/44){.Information{display: none;}}
@media screen and (max-aspect-ratio: 100/72){.Information{display: none;}}
@media screen and (max-aspect-ratio: 100/83){.Menu{display: none;}}
@media screen and (max-aspect-ratio: 55/100){.LogoText{font-size: 1.4vh;margin-left: 15vh;}}
@media screen and (max-aspect-ratio: 60/100)
{
    .Img1Text{margin-left: 8vw; font-size: 6vw;}
    .SolutionTitle{font-size: 5vw;}
    .SolutionText{font-size: 3vw; margin-left: 20vw; line-height: 8vw;}
    .ServiceTitle{font-size: 5vw;}
    .ServiceText2{font-size: 4vw; line-height: 10vw;}
    .Cost{font-size: 5vw;}
    .Cost2{margin-left: 10vw; font-size: 3.7vw;}
    .Cost4{margin-left: 10vw; font-size: 5vw;}
    .Cost5{margin-left: 10vw; font-size: 3vw;}
    .Border{width: 90vw; margin-left: 5vw;}
    .NemyTitle{width: 80vw; height: 14vw; margin-top: -20vw;  margin-left: 10vw; font-size: 9vw;}
    .NemyTitleSet{ width: 14vw; height: 14vw;  margin-top: 2vw; margin-left: 43vw;}
    .Text3{font-size: 5vw; line-height: 10vw;}
    .Text4{font-size: 3.5vw; line-height: 10vw;}
    .CaseImg{height: 80vw;}
    .NemyText{font-size: 4vw; margin-left: 10vw;}
    .ArrowImg{width: 100vw;}
    .Question{margin-left: 10vw; font-size: 5vw;}
    .Answer{margin-left: 10vw; font-size: 4vw; line-height: 8vw;}
    .fuder1{margin-left: 10vw; font-size: 5vw;}
    .fuder2{font-size: 3.5vw; line-height: 9vw;}
    .Img2{width: 100vw;}
}